import { Button } from "@components-v3/Button";
import LayOut from "@components/layout";
import { Container, MenuButtonGatsbyLink } from "@util/standard";
import React from "react";
import styled from "styled-components";
import upgradeImage from "../../static/assets/upgrade.png";

const StyledLink = styled(MenuButtonGatsbyLink)`
  width: fit-content;
  padding-inline: 40px;
`;

const SignIn = () => {
  return (
    <LayOut>
      <Container
        margin="50px auto 50px auto"
        paddingHorizontal={20}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        isMobileColumn
        mobileTextAlign="center"
      >
        <h2>We currently don't support plan upgrade on mobile.</h2>
        <p>Please use a desktop device to upgrade to Celo Business plan.</p>
        <img
          src={upgradeImage}
          height="100%"
          style={{ maxHeight: "200px" }}
        ></img>
        <Container
          textAlign="center"
          mobileTextAlign="center"
          display="flex"
          alignItems="center"
          flexDirection="column"
          margin="50px auto 25px auto"
          mobileMargin="30px auto 30px auto"
        >
          <p style={{ marginBottom: "5px" }}>Need help getting started?</p>
          <Button
            isPink={false}
            linkText={"Contact us for help."}
            url="/contact-us"
            linkStyle="link"
          />
        </Container>
        <StyledLink href="/features#plans" color="secondary">
          Go Back
        </StyledLink>
      </Container>
    </LayOut>
  );
};

export default SignIn;
